export default [
  {
    path: '/patrol-summary',
    name: 'patrol-summary',
    component: () => import('@/views/apps/report/Patrol.vue'),
    meta: {
      pageTitle: 'Patrol Summary',
      breadcrumb: [
        {
          text: 'Patrol Summary',
          active: true,
        },
      ],
    },
  },
]
