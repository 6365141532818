export default [
  {
    path: '/settings/users',
    name: 'settings-users',
    component: () => import('@/views/apps/settings/users/users-list/Users.vue'),
    meta: {
      pageTitle: 'Settings - Users',
      breadcrumb: [
        {
          text: 'Settings - Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/users/create',
    name: 'settings-users-create',
    component: () => import('@/views/apps/settings/users/users-create/UsersCreate.vue'),
  },
  {
    path: '/settings/users/edit/:id',
    name: 'settings-users-edit',
    component: () => import('@/views/apps/settings/users/users-edit/UsersEdit.vue'),
  },
]
