import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn } from '@/auth/utils'
// import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import patrolSummary from './routes/patrol-summary'
import masterArea from './routes/master-area'
import masterCheckpoint from './routes/master-checkpoint'
import settingsUsers from './routes/settings-users'
import settingsGuards from './routes/settings-guards'
import settingsRoles from './routes/settings-role'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    // ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...patrolSummary,
    ...masterArea,
    ...masterCheckpoint,
    ...settingsUsers,
    ...settingsGuards,
    ...settingsRoles,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {

  const isLoggedIn = isUserLoggedIn()
  
  // const canNavigateVar = canNavigate(to)
  console.log(isLoggedIn)
  // Redirect to login if not logged in
  if (to.name !== 'auth-login' && !isLoggedIn) next({ name: 'auth-login' })
  else next()
  // } else if (!canNavigateVar) {
  //   // If logged in => not authorized
  //   console.log('masuk eui 2')
  //   return '/pages/miscellaneous/not-authorized'

  return router
})

// Check local storage to handle refreshes
if (window.localStorage) {
  const localUserString = window.localStorage.getItem('userData') || 'null'
  const localUser = JSON.parse(localUserString)
  console.log('ini store customconfig user')
  console.log(store.state.customConfig.user)
  if (localUser && store.state.customConfig.user !== localUser) {
    store.commit('customConfig/SET_TOKEN', window.localStorage.getItem('apiKey').replace(/['"]+/g, ''))
    store.commit('customConfig/SET_USER', localUser)
  }
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
