export default {
  namespaced: true,
  state: {
    baseURL: 'https://ciptabuanalestari.com/monitoring/api',
    user: null,
    token: null,
  },
  getters: {
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
  },
  actions: {},
}
