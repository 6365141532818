export default [
  // {
  //   path: '/dashboard/analytics',
  //   name: 'dashboard-analytics',
  //   component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  // },
  // {
  //   path: '/dashboard/ecommerce',
  //   name: 'dashboard-ecommerce',
  //   component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  // },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'view',
      subject: 'dashboard',
    },
  },
  {
    path: '/dashboard/fullview',
    name: 'dashboard-full-view',
    component: () => import('@/views/dashboard/DashboardFullView.vue'),
    meta: {
      pageTitle: 'Monitoring All Site',
      breadcrumb: [
        {
          text: 'Monitoring All Site',
          active: true,
        },
      ],
      requiresAuth: true,
      action: 'view',
      subject: 'dashboardFullView',
    },
  },
]
