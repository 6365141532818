export default [
  {
    path: '/settings/guards',
    name: 'settings-guards',
    component: () => import('@/views/apps/settings/guards/guards-list/Guards.vue'),
    meta: {
      pageTitle: 'Settings - Guards',
      breadcrumb: [
        {
          text: 'Settings - Guards',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/guards/create',
    name: 'settings-guards-create',
    component: () => import('@/views/apps/settings/guards/guards-create/GuardsCreate.vue'),
  },

  {
    path: '/settings/guards/edit/:id',
    name: 'settings-guards-edit',
    component: () => import('@/views/apps/settings/guards/guards-edit/GuardsEdit.vue'),
  },
]
