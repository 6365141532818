export default [
  {
    path: '/settings/roles',
    name: 'settings-roles',
    component: () => import('@/views/apps/settings/roles/role-list/Role.vue'),
    meta: {
      pageTitle: 'Settings - Roles',
      breadcrumb: [
        {
          text: 'Settings - Roles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/roles/create',
    name: 'settings-roles-create',
    component: () => import('@/views/apps/settings/roles/role-create/RoleCreate.vue'),
  },
  {
    path: '/settings/roles/edit/:id',
    name: 'settings-roles-edit',
    component: () => import('@/views/apps/settings/roles/role-edit/RoleEdit.vue'),
  },
]
