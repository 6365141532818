export default [
  {
    path: '/masterdata/area',
    name: 'master-data-area',
    component: () => import('@/views/apps/master-data/area/area-list/Area.vue'),
    meta: {
      pageTitle: 'Master Data - Site',
      breadcrumb: [
        {
          text: 'Master Data - Site',
          active: true,
        },
      ],
    },
  },
  {
    path: '/masterdata/area/create',
    name: 'master-data-area-create',
    component: () => import('@/views/apps/master-data/area/area-create/AreaCreate.vue'),
  },
  {
    path: '/masterdata/area/edit/:id',
    name: 'master-data-area-edit',
    component: () => import('@/views/apps/master-data/area/area-edit/AreaEdit.vue'),
  },
]
