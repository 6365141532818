const MyPlugin = {
  install(Vue, options) {
    console.log(options)
    Vue.prototype.$confirm = function () {
      return window.confirm('Do you really want to submit?')
    }

    Vue.prototype.$onDataSuccess = function (response) {
      // if (typeof this.apiData.status_text !== 'undefined') {
      //   $('#searchResult').removeClass('hide')
      // } else {
      //   alert('Data not found')
      // }
      // window.location = '/#/sukses/' + response
      console.log(response)
    }

    Vue.prototype.$onDataError = function (e) {
      if (e.status === 403 || e.status === 500 || e.status === 400) {
        console.log(e)
        alert(JSON.parse(e.errortext).error)
      } else if (e.status === -1) {
        // Remove userData from localStorage
        localStorage.removeItem('userData')
        localStorage.removeItem('apiKey')
  
        // Reset ability
        this.$ability.update(initialAbility)
  
        // Redirect to login page
        this.$router.push({ name: 'auth-login' })
        
      } else {
        alert('Komunikasi ke server terganggu silahkan coba kembali. Apabila problem terjadi terus menerus silahkan hubungi technical support')
      }
    }
  },
}

export default MyPlugin
